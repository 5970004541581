import { DAY_TYPE } from 'typings/articles';
import { Holiday } from 'typings/holiday';
import { Profession } from 'typings/titles';
import { formatHoursToHHMM } from 'utils/utils_legacy';

export interface TimesheetResponse {
    timesheet?: Timesheet;
    assignmentInfo?: AssignmentInfo;
    timesheetSetting?: TIMESHEET_SETTING;

    // TotalTimereportedHoursForWeek is the total time reported for the week.
    // Excluding the assignment that the timesheet is connected to.
    otherTimeReportedHoursForWeek: OtherTimeReportedHoursForWeek;
}

export interface OtherTimeReportedHoursForWeek {
    hours: number;
    count: number;
}

export interface Timesheet {
    id?: number;
    date: string;
    submitter?: Submitter;
    assignmentID?: number;
    days: TimesheetDay[];

    totalHours: string;
    totalReportedDays: number;
    summary: string[];

    totalNormalHours: string;
    summaryNormalHours: string[];

    totalSickHours: string;
    summarySickHours: string[];

    notes: string;
    travelsheet?: Travelsheet;
    template: TimesheetTemplate;
    year: number;
    week: number;

    hasSickHours: boolean;
}

export interface TimesheetDay {
    date: string;
    year: number;
    month: number;
    day: number;
    weekday: number; // (Sunday = 0, ...)
    holiday?: Holiday;

    // The offset from the start date. 0 is the first day.
    dayOffset: number;

    // one entry per column
    hours: string[];
    sickHours: string[];

    // only set if the timesheet has been reported by using intervals
    intervals: TimesheetDayInterval[];

    // if the day is added due to interval overflow and has no intervals by itself
    isOverflow: boolean;
}

export interface TimesheetDayInterval {
    start: string;
    stop: string;
    breakInMinutes: number;
    isSickHours: boolean;
}

interface Submitter {
    id: number;
    name: string;
    surname: string;
}

export const hasSickHours = (
    timesheet: Timesheet,
    setting: TIMESHEET_SETTING
): boolean => {
    for (let i = 0; i < timesheet.days.length; i++) {
        const day = timesheet.days[i];
        if (setting === TIMESHEET_SETTING.NORMAL) {
            if (day.sickHours && day.sickHours.length > 0) {
                return true;
            }
        } else if (setting === TIMESHEET_SETTING.START_STOP_TIME) {
            if (day.intervals) {
                for (let j = 0; j < day.intervals.length; j++) {
                    const interval = day.intervals[j];
                    if (interval.isSickHours) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
};

export const addSickHours = (
    timesheet: Timesheet,
    setting: TIMESHEET_SETTING
): Timesheet => {
    const t: Timesheet = { ...timesheet };
    const count = timesheet.template.subheader.length;

    for (let i = 0; i < timesheet.days.length; i++) {
        const day = timesheet.days[i];
        if (setting === TIMESHEET_SETTING.NORMAL) {
            const current = day.sickHours || [];
            const diff = Math.max(count - current.length, 0);
            t.days[i] = {
                ...day,
                sickHours: [...current, ...Array(diff).fill('')],
            };
        } else if (setting === TIMESHEET_SETTING.START_STOP_TIME) {
            const current = day.intervals || [];
            if (!current[1]) {
                day.intervals[1] = {
                    start: '',
                    stop: '',
                    breakInMinutes: 0,
                    isSickHours: true,
                };
            }
        }
    }

    return t;
};

export const calculateTotalReportedTimeAsNumber = (
    timesheet: Timesheet,
    setting: TIMESHEET_SETTING
): number => {
    let sum = 0;

    for (let i = 0; i < timesheet.days.length; i++) {
        const day = timesheet.days[i];

        if (setting === TIMESHEET_SETTING.NORMAL) {
            for (let j = 0; j < day.hours.length; j++) {
                const v = parseFloat(day.hours[j]) || 0;
                sum = (sum || 0) + v;
            }

            if (day.sickHours) {
                for (let j = 0; j < day.sickHours.length; j++) {
                    const v = parseFloat(day.sickHours[j]) || 0;
                    sum = (sum || 0) + v;
                }
            }
        } else if (
            setting === TIMESHEET_SETTING.START_STOP_TIME &&
            day.intervals
        ) {
            for (let j = 0; j < day.intervals.length; j++) {
                const interval = day.intervals[j];
                const start = interval.start.split(':');
                const stop = interval.stop.split(':');
                const startMinutes =
                    parseInt(start[0]) * 60 + parseInt(start[1]);
                let stopMinutes = parseInt(stop[0]) * 60 + parseInt(stop[1]);
                if (!startMinutes || !stopMinutes) {
                    continue;
                }
                if (stopMinutes < startMinutes) {
                    stopMinutes += 24 * 60;
                }
                const diff =
                    stopMinutes - startMinutes - interval.breakInMinutes;
                if (diff > 0) {
                    sum += diff / 60.0;
                }
            }
        }
    }

    return sum;
};

export const calculateTotalReportedTime = (
    timesheet: Timesheet,
    setting: TIMESHEET_SETTING
): string => {
    return formatHoursToHHMM(
        calculateTotalReportedTimeAsNumber(timesheet, setting),
        false
    );
};

export const calculateSumPerColumn = (timesheet: Timesheet): Summary => {
    const sum: number[] = Array(timesheet.template.subheader.length).fill(0);

    for (let i = 0; i < timesheet.days.length; i++) {
        const day = timesheet.days[i];

        for (let j = 0; j < day.hours.length; j++) {
            const v = parseFloat(day.hours[j]) || 0;
            sum[j] = (sum[j] || 0) + v;
        }

        if (day.sickHours) {
            for (let j = 0; j < day.sickHours.length; j++) {
                const v = parseFloat(day.sickHours[j]) || 0;
                sum[j] = (sum[j] || 0) + v;
            }
        }
    }

    return sum.map(v => formatHoursToHHMM(v, false));
};

export interface Travelsheet {
    id: number;
    date: Date | string;
    travel1: Array<string>;
    travel2: Array<string>;
    travel3: Array<string>;
    travel4: Array<string>;
    travel5: Array<string>;
    travel6: Array<string>;
    travel7: Array<string>;
    totalMileage: string;
    totalPayment: string;
}

export const calculateTravelsheetColumnSum = (
    travelsheet: Travelsheet,
    index: number
): string => {
    let v = 0;
    for (const k in travelsheet) {
        if (isTravelsheetKeyType(k) && travelsheet[k]) {
            v +=
                travelsheet[k] && travelsheet[k]?.[index]
                    ? parseFloat(travelsheet[k][index]) || 0
                    : 0;
        }
    }

    return v?.toFixed(1) || '';
};

export type TravelsheetKeyType =
    | 'travel1'
    | 'travel2'
    | 'travel3'
    | 'travel4'
    | 'travel5'
    | 'travel6'
    | 'travel7';

const isTravelsheetKeyType = (key: any): key is TravelsheetKeyType => {
    const keys: Array<TravelsheetKeyType> = [
        'travel1',
        'travel2',
        'travel3',
        'travel4',
        'travel5',
        'travel6',
        'travel7',
    ];
    return keys.includes(key);
};

export interface TimesheetTemplate {
    id: number;
    name: string;
    header: Array<TemplateHeader>;
    subheader: Array<TemplateColumn>;

    recalcTitle: string;
    hasSumrecalc: boolean;
    sumrecalcTitle: string;
    totalTitle: string;
    totalColspan: number;
    footnote: Footnote | null;
    hasComment: boolean;
    showInList: boolean;

    profession: Profession;
}

export interface TemplateHeader {
    text: string;
    colspan: string;
}

export interface TemplateColumn {
    text: string;
    maxHours: number;
}

export interface TemplateColumn {
    text: string;
    maxHours: number;
    isJour: boolean;
    interval: Interval[];
}

export interface Interval {
    from: number | null;
    to: number | null;
    dayType: DAY_TYPE;
}

interface Footnote {
    text: string;
    colspan: string;
}

export interface AssignmentInfo {
    year: number;
    week: number;
    allowTravelsheet: boolean;
    status: { id: number; label: string };
    carePlace: { id?: number; name: string };
    staff?: { id?: number; name: string };
    uuid?: string;
}

export enum TIMESHEET_SETTING {
    NORMAL = 'NORMAL',
    START_STOP_TIME = 'START_STOP_TIME',
}

export interface TimesheetVersion {
    id: number;
    date: string;
    submitter: Submitter | null;
}

export interface InsertTimesheetPayload {
    days: TimesheetDayPayload[];
    notes: string;
    timesheetTemplateID?: number;
    travelsheet?: TravelsheetPayload;
}

interface TimesheetDayPayload {
    hours: string[];
    sickHours: string[];
    intervals: TimesheetDayIntervalPayload[];
}

interface TimesheetDayIntervalPayload {
    start: string;
    stop: string;
    breakInMinutes: number;
    isSickHours: boolean;
}

interface TravelsheetPayload {
    travel1: string[];
    travel2: string[];
    travel3: string[];
    travel4: string[];
    travel5: string[];
    travel6: string[];
    travel7: string[];
}

export function buildTimesheetPayload(
    t: Timesheet,
    enableSickHours: boolean,
    setting: TIMESHEET_SETTING
): InsertTimesheetPayload {
    return {
        days: t.days.map(v =>
            buildTimesheetDayPayload(v, enableSickHours, setting)
        ),
        notes: t.notes,
        timesheetTemplateID: t.template ? t.template.id : undefined,
        travelsheet: t.travelsheet
            ? buildTravelsheetPayload(t.travelsheet)
            : undefined,
    };
}

function buildTimesheetDayPayload(
    t: TimesheetDay,
    enableSickHours: boolean,
    setting: TIMESHEET_SETTING
): TimesheetDayPayload {
    return {
        hours: t.hours,
        sickHours: enableSickHours ? t.sickHours : [],
        intervals:
            setting === TIMESHEET_SETTING.NORMAL
                ? []
                : enableSickHours
                ? t.intervals
                : t.intervals.filter(v => !v.isSickHours),
    };
}

function buildTravelsheetPayload(t: Travelsheet): TravelsheetPayload {
    return {
        travel1: t.travel1,
        travel2: t.travel2,
        travel3: t.travel3,
        travel4: t.travel4,
        travel5: t.travel5,
        travel6: t.travel6,
        travel7: t.travel7,
    };
}

// The summary row in the timesheet table.
export type Summary = string[];
