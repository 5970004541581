import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { TemplateColumn, TimesheetDay, TimesheetTemplate } from '../typings';
import { extractHoursAndMinutes, formatHoursToHHMM } from 'utils/utils_legacy';
import Typography from '@mui/material/Typography';
import TextField, { Label } from 'components/interface/textField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
    extractNumber,
    extractMinutesFromHoursDecimals,
    minutesToHoursDecimals,
} from 'utils/utils';
import { red } from '@mui/material/colors';
import { Tooltip } from '@mui/material';
import SickOutlined from '@mui/icons-material/SickOutlined';

const useStyles = makeStyles()(theme => ({
    base: {
        width: '100%',
    },
    group: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    groupTitle: {
        marginBottom: theme.spacing(1),
    },

    textFieldRowWrapper: {
        'width': '100%',
        'display': 'flex',
        'flexDirection': 'column',
        '& + &': {
            marginTop: theme.spacing(1),
        },
    },
    textFieldWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textField: {
        width: '100%',
    },
    splitTextField: {
        flexBasis: '48%',
    },
    isSickHours: {},
    sickHoursIcon: {
        color: red[900],
    },
}));

interface Props {
    day: TimesheetDay;
    dayIndex: number;
    template: TimesheetTemplate;
    useSplitTextfields: boolean;
    canSubmit: boolean;
    loading: boolean;
    enableSickHours: boolean;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
}

export default function NormalTimesheet({
    day,
    dayIndex,
    template,
    useSplitTextfields,
    canSubmit,
    loading,
    enableSickHours,
    onUpdateTimesheetDay,
}: Props): JSX.Element {
    const { classes, cx } = useStyles();
    const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({});
    const subheaders = template.subheader || [];

    const handleUpdateTimesheetDay = (
        value: string,
        event: React.ChangeEvent<HTMLInputElement>,
        columnIndex: number,
        type: 'hour' | 'minutes',
        isSickHours: boolean
    ) => {
        if (event?.target?.validity?.badInput) {
            setErrors(prev => ({
                ...prev,
                [columnIndex + '-' + isSickHours]: true,
            }));
        } else if (errors[columnIndex + '-' + isSickHours]) {
            setErrors(prev => ({
                ...prev,
                [columnIndex + '-' + isSickHours]: false,
            }));
        }

        let maxHours = 24;
        if (subheaders[columnIndex].maxHours) {
            maxHours = subheaders[columnIndex].maxHours;
        }

        const minFloat = (a: number, b: number) =>
            a > b ? extractNumber(b + '', 3) : extractNumber(a + '', 3);

        const key = isSickHours ? 'sickHours' : 'hours';

        onUpdateTimesheetDay(prev => {
            const d = { ...prev };
            d[key] = [...d[key]];

            if (useSplitTextfields) {
                // when using split textfields you enter hours and minutes separate. Therefore the logic is special.
                if (type === 'hour') {
                    value = extractNumber(value, 3);
                    value = parseFloat(value) > 24 ? '24' : value + '';
                    let hour = value ? parseInt(value) : 0;
                    let minutesDecimals = extractMinutesFromHoursDecimals(
                        d[key][columnIndex]
                    ); // This turns for example 4.25 into 0.25, it only keeps the decimals
                    d[key][columnIndex] = minFloat(
                        hour + minutesDecimals,
                        maxHours
                    );
                } else {
                    value = extractNumber(value, 3);
                    value = parseFloat(value) > 59 ? '59' : value + '';
                    let hour = d[key][columnIndex]
                        ? parseInt(d[key][columnIndex])
                        : 0;
                    let minutesDecimals = minutesToHoursDecimals(value); // This turns for example 15 into 0.25
                    d[key][columnIndex] = minFloat(
                        hour + minutesDecimals,
                        maxHours
                    );
                }
            } else {
                value = extractNumber(value, 3);
                d[key][columnIndex] =
                    parseFloat(value) > maxHours ? maxHours + '' : value + '';
            }

            return d;
        }, dayIndex);
    };

    const buildGroups = (): Array<JSX.Element> => {
        let returnData: Array<JSX.Element> = [];
        let start = 0;
        for (let i = 0; i < template?.header.length; i++) {
            let colspan = parseInt(template?.header[i].colspan);
            // This is because the first header stretches over the column with the dates, meaning that its colspan is one too large.
            if (i === 0) {
                colspan--;
            }
            let end = start + colspan;
            returnData.push(
                buildGroup(
                    template?.header[i].text,
                    template?.subheader.slice(start, end),
                    day.hours.slice(start, end),
                    day.sickHours.slice(start, end),
                    start
                )
            );
            start += colspan;
        }
        return returnData;
    };

    const buildGroup = (
        name: string,
        subheader: Array<TemplateColumn>,
        hours: Array<string>,
        sickHours: Array<string>,
        columnStartIndex: number
    ): JSX.Element => {
        let returnData = [];

        for (let i = 0; i < subheader.length; i++) {
            returnData.push(
                buildTextField(
                    subheader[i].text,
                    hours[i],
                    columnStartIndex + i,
                    false
                )
            );
            if (enableSickHours) {
                returnData.push(
                    buildTextField(
                        subheader[i].text,
                        sickHours[i],
                        columnStartIndex + i,
                        true
                    )
                );
            }
        }
        return (
            <div key={name} className={classes.group}>
                <Typography
                    variant='body1'
                    color='textPrimary'
                    className={classes.groupTitle}>
                    <b>{name}</b>
                </Typography>
                {returnData}
            </div>
        );
    };

    const buildTextField = (
        label: string,
        value: string,
        columnIndex: number,
        isSickHours: boolean
    ): JSX.Element => {
        const splitTime = useSplitTextfields
            ? extractHoursAndMinutes(value)
            : null;
        const hourText = useSplitTextfields
            ? splitTime?.hours?.toString()
            : value;
        const minutesText = useSplitTextfields
            ? splitTime?.minutes?.toString()
            : '';
        return (
            <div
                className={classes.textFieldRowWrapper}
                key={columnIndex + 'wrapper' + isSickHours}>
                {isSickHours ? null : (
                    <Label text={label.replaceAll('<br>', ' ')} />
                )}
                <div
                    className={cx(classes.textFieldWrapper, {
                        [classes.isSickHours]: isSickHours,
                    })}>
                    <TextField
                        key={columnIndex}
                        value={hourText || ''}
                        type='number'
                        lang='en'
                        inputMode='decimal'
                        onChange={(v, e) =>
                            handleUpdateTimesheetDay(
                                v,
                                e,
                                columnIndex,
                                'hour',
                                isSickHours
                            )
                        }
                        disabled={!canSubmit || loading}
                        classes={{
                            base: cx({
                                [classes.splitTextField]: useSplitTextfields,
                                [classes.textField]: !useSplitTextfields,
                            }),
                        }}
                        InputProps={{
                            startAdornment: isSickHours ? (
                                <InputAdornment position='start'>
                                    <Tooltip
                                        title='Sjuktimmar'
                                        placement='top'
                                        disableInteractive>
                                        <SickOutlined
                                            className={classes.sickHoursIcon}
                                        />
                                    </Tooltip>
                                </InputAdornment>
                            ) : null,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    timmar
                                </InputAdornment>
                            ),
                        }}
                        error={errors[columnIndex + '-' + isSickHours]}
                        helperText={
                            errors[columnIndex + '-' + isSickHours]
                                ? 'Felaktigt format.'
                                : useSplitTextfields
                                ? ''
                                : formatHoursToHHMM(hourText, true)
                        }
                        fullWidth={!useSplitTextfields}
                    />
                    {useSplitTextfields ? (
                        <TextField
                            key={columnIndex + 'minutes'}
                            value={minutesText || ''}
                            type='number'
                            onChange={(v, e) =>
                                handleUpdateTimesheetDay(
                                    v,
                                    e,
                                    columnIndex,
                                    'minutes',
                                    isSickHours
                                )
                            }
                            disabled={!canSubmit || loading}
                            classes={{
                                base: cx({
                                    [classes.splitTextField]:
                                        useSplitTextfields,
                                    [classes.textField]: !useSplitTextfields,
                                }),
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        minuter
                                    </InputAdornment>
                                ),
                            }}
                            error={errors[columnIndex + '-' + isSickHours]}
                            helperText={
                                errors[columnIndex + '-' + isSickHours]
                                    ? ' '
                                    : ''
                            }
                        />
                    ) : null}
                </div>
            </div>
        );
    };

    return <div className={classes.base}>{buildGroups()}</div>;
}
