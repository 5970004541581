import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Timesheet,
    TemplateHeader,
    TemplateColumn,
    TimesheetDay,
    Summary,
} from '../typings';
import { extractHoursAndMinutes } from 'utils/utils_legacy';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import TextField from 'components/interface/textField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
    extractNumber,
    extractMinutesFromHoursDecimals,
    minutesToHoursDecimals,
} from 'utils/utils';
import { WEEKDAY } from '../utils';
import { red, pink } from '@mui/material/colors';
import { Tooltip, lighten } from '@mui/material';
import SickOutlined from '@mui/icons-material/SickOutlined';

const useStyles = makeStyles()(theme => ({
    table: {
        marginBottom: theme.spacing(2),
        background: '#fff',
    },
    headerCell: {
        background: theme.palette.primary.main,
        color: '#fff',
        fontSize: '14px',
    },
    subheaderCell: {
        background: grey[200],
        color: grey[800],
        fontSize: '14px',
        fontWeight: '400',
    },
    cell: {
        'height': '48px',
        'wordBreak': 'break-word',
        'hyphens': 'auto',
        'textAlign': 'center',
        'paddingLeft': theme.spacing(2),
        'paddingRight': theme.spacing(2),
        'border': '1px solid ' + grey[800],
        '&:last-child': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    cellSums: {
        background: theme.palette.primary['200'],
    },
    publicHoliday: {
        color: pink[900],
        background: pink[50],
    },
    isSickHours: {
        background: lighten(red[50], 0.4),
        borderTop: '2px dotted ' + grey[500],
    },
    sickHoursIcon: {
        color: red[900],
    },
    splitTextFieldWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    splitTextField: {
        flexBasis: '45%',
    },
}));

interface Props {
    timesheet: Timesheet;
    summary: Summary;
    useSplitTextfields: boolean;
    canSubmit: boolean;
    loading: boolean;
    enableSickHours: boolean;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
}

export default function NormalTimesheet({
    timesheet,
    summary,
    useSplitTextfields,
    canSubmit,
    loading,
    enableSickHours,
    onUpdateTimesheetDay,
}: Props): JSX.Element {
    const { classes, cx } = useStyles();
    const template = timesheet.template;
    const subheaders = template.subheader || [];

    const renderHeaderCell = (
        { text, colspan }: TemplateHeader,
        key: number
    ) => {
        return (
            <TableCell
                align='center'
                key={key}
                colSpan={parseInt(colspan)}
                className={cx(classes.headerCell, classes.cell)}>
                {text}
            </TableCell>
        );
    };

    const renderSubheaderCell = ({ text }: TemplateColumn, key: number) => {
        return (
            <TableCell
                align='center'
                key={key}
                colSpan={1}
                className={cx(classes.subheaderCell, classes.cell)}
                dangerouslySetInnerHTML={{ __html: text }}></TableCell>
        );
    };

    const renderFooter = () => {
        const { totalTitle, totalColspan, footnote } = template;
        const totalHours = timesheet?.totalHours;
        return (
            <TableRow>
                <TableCell className={classes.cell}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        totalTitle
                    )}
                </TableCell>
                <TableCell
                    colSpan={totalColspan}
                    className={cx(classes.cell, classes.cellSums)}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        totalHours || ''
                    )}
                </TableCell>
                <TableCell
                    colSpan={
                        footnote?.colspan ? parseInt(footnote?.colspan) : 4
                    }
                    className={classes.cell}
                    {...(loading
                        ? {}
                        : {
                              dangerouslySetInnerHTML: {
                                  __html: footnote?.text || '',
                              },
                          })}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='300px'
                        />
                    ) : null}
                </TableCell>
            </TableRow>
        );
    };

    const renderPage = (): JSX.Element => {
        return (
            <Table
                className={classes.table}
                padding='none'
                style={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow>
                        {template.header.map((col, i) =>
                            renderHeaderCell(col, i)
                        )}
                    </TableRow>
                    <TableRow>
                        {renderSubheaderCell(
                            {
                                text: '',
                                maxHours: 0,
                                isJour: false,
                                interval: [],
                            },
                            -1
                        )}
                        {template.subheader.map((col, i) =>
                            renderSubheaderCell(col, i)
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timesheet.days.map((day, index) => (
                        <Row
                            key={index}
                            {...day}
                            subheaders={subheaders}
                            index={index}
                            canSubmit={canSubmit}
                            useSplitTextfields={useSplitTextfields}
                            loading={loading}
                            enableSickHours={enableSickHours}
                            onUpdateTimesheetDay={onUpdateTimesheetDay}
                        />
                    ))}
                    <SummaryRow columns={summary} loading={loading} />
                    {renderFooter()}
                </TableBody>
            </Table>
        );
    };

    return renderPage();
}

interface RowProps extends TimesheetDay {
    subheaders: TemplateColumn[];
    index: number;
    canSubmit: boolean;
    useSplitTextfields: boolean;
    loading: boolean;
    enableSickHours: boolean;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
}

const Row = ({
    month,
    day,
    weekday,
    holiday,
    hours,
    sickHours,
    subheaders,
    index,
    canSubmit,
    useSplitTextfields,
    loading,
    enableSickHours,
    onUpdateTimesheetDay,
}: RowProps) => {
    const { classes, cx } = useStyles();

    const handleUpdateTimesheetDay = (
        value: string,
        columnIndex: number,
        type: 'hour' | 'minutes',
        isSickHours: boolean
    ) => {
        let maxHours = 24;
        if (subheaders[columnIndex].maxHours) {
            maxHours = subheaders[columnIndex].maxHours;
        }

        const minFloat = (a: number, b: number) =>
            a > b ? extractNumber(b + '', 3) : extractNumber(a + '', 3);

        const key = isSickHours ? 'sickHours' : 'hours';

        onUpdateTimesheetDay(prev => {
            const d = { ...prev };
            d[key] = [...d[key]];

            if (useSplitTextfields) {
                // when using split textfields you enter hours and minutes separate. Therefore the logic is special.
                if (type === 'hour') {
                    value = extractNumber(value, 3);
                    value = parseFloat(value) > 24 ? '24' : value + '';
                    const hour = value ? parseInt(value) : 0;
                    const minutesDecimals = extractMinutesFromHoursDecimals(
                        d[key][columnIndex]
                    ); // This turns for example 4.25 into 0.25, it only keeps the decimals
                    d[key][columnIndex] = minFloat(
                        hour + minutesDecimals,
                        maxHours
                    );
                } else {
                    value = extractNumber(value, 3);
                    value = parseFloat(value) > 59 ? '59' : value + '';
                    const hour = d[key][columnIndex]
                        ? parseInt(d[key][columnIndex])
                        : 0;
                    const minutesDecimals = minutesToHoursDecimals(value); // This turns for example 15 into 0.25
                    d[key][columnIndex] = minFloat(
                        hour + minutesDecimals,
                        maxHours
                    );
                }
            } else {
                value = extractNumber(value, 3);
                d[key][columnIndex] =
                    parseFloat(value) > maxHours ? maxHours + '' : value + '';
            }

            return d;
        }, index);
    };

    const buildDayCell = (
        value: string,
        columnIndex: number,
        isSickHours: boolean
    ) => {
        const splitTime = useSplitTextfields
            ? extractHoursAndMinutes(value)
            : null;
        // this will turn 0 into an empty string
        const hourText = useSplitTextfields
            ? splitTime?.hours
                ? splitTime?.hours?.toString()
                : ''
            : value;
        const minutesText = useSplitTextfields
            ? splitTime?.minutes
                ? splitTime?.minutes?.toString()
                : ''
            : '';

        if (canSubmit) {
            return (
                <TableCell
                    key={columnIndex}
                    colSpan={1}
                    className={cx(classes.cell, {
                        [classes.isSickHours]: isSickHours,
                    })}>
                    <div
                        className={cx({
                            [classes.splitTextFieldWrapper]: useSplitTextfields,
                        })}>
                        <TextField
                            value={hourText || ''}
                            onChange={v =>
                                handleUpdateTimesheetDay(
                                    v,
                                    columnIndex,
                                    'hour',
                                    isSickHours
                                )
                            }
                            inputProps={{
                                style: { textAlign: 'right' },
                            }}
                            InputProps={{
                                startAdornment:
                                    isSickHours && columnIndex === 0 ? (
                                        <InputAdornment position='start'>
                                            <Tooltip
                                                title='Sjuktimmar'
                                                placement='top'
                                                disableInteractive>
                                                <SickOutlined
                                                    className={
                                                        classes.sickHoursIcon
                                                    }
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                    ) : null,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        h
                                    </InputAdornment>
                                ),
                            }}
                            classes={{
                                base: cx({
                                    [classes.splitTextField]:
                                        useSplitTextfields,
                                }),
                            }}
                            noBorder
                            variant={'standard'}
                        />
                        {useSplitTextfields ? (
                            <TextField
                                value={minutesText || ''}
                                onChange={v =>
                                    handleUpdateTimesheetDay(
                                        v,
                                        columnIndex,
                                        'minutes',
                                        isSickHours
                                    )
                                }
                                inputProps={{
                                    style: { textAlign: 'right' },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                                classes={{
                                    base: cx({
                                        [classes.splitTextField]:
                                            useSplitTextfields,
                                    }),
                                }}
                                noBorder
                                variant='standard'
                            />
                        ) : null}
                    </div>
                </TableCell>
            );
        } else {
            return (
                <TableCell
                    key={columnIndex}
                    colSpan={1}
                    className={cx(classes.cell, {
                        [classes.isSickHours]: isSickHours,
                    })}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        value
                    )}
                </TableCell>
            );
        }
    };

    return (
        <>
            <TableRow>
                <TableCell
                    className={cx(classes.cell, {
                        [classes.publicHoliday]: holiday?.IsPublicHoliday,
                    })}
                    rowSpan={enableSickHours ? 2 : 1}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        <Tooltip
                            enterTouchDelay={500}
                            title={holiday?.Name}
                            placement='top'
                            disableInteractive>
                            <span>
                                <Typography variant='body2' color='inherit'>
                                    {`${WEEKDAY[weekday]} ${day}/${month}`}
                                </Typography>
                                {holiday?.IsMajorHoliday ? (
                                    <Typography
                                        variant='caption'
                                        color='inherit'>
                                        storhelg
                                    </Typography>
                                ) : null}
                            </span>
                        </Tooltip>
                    )}
                </TableCell>
                {hours.map((v, i) => buildDayCell(v, i, false))}
            </TableRow>
            {enableSickHours ? (
                <TableRow>
                    {sickHours.map((v, i) => buildDayCell(v, i, true))}
                </TableRow>
            ) : null}
        </>
    );
};

interface SummaryRowProps {
    columns: Summary;
    loading: boolean;
}

const SummaryRow = ({ columns, loading }: SummaryRowProps) => {
    const { classes, cx } = useStyles();

    return (
        <TableRow>
            <TableCell className={classes.cell}>
                <Typography variant='body2' color='inherit'>
                    Summa antal timmar
                </Typography>
            </TableCell>
            {columns.map((v, i) => (
                <TableCell
                    key={i}
                    className={cx(classes.cell, classes.cellSums)}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        v
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
};
